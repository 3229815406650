<template>
    <CoreachNavbar />
    <main class="relative">
        <section class="bg-white">
            <div class="grid lg:grid-cols-12 lg:py-16 mx-auto py-8 max-h-screen">
                <div class="lg:col-span-7 place-self-center text-left">
                    <div class="p-4 md:p-10 lg:p-16">
                        <h1 class="font-extrabold font-sora leading-none mb-4 md:text-7xl text-6xl tracking-tight xl:text-8xl">Redefining<br/> What It Means</h1>
                        <p class="font-extrabold font-sora lg:mb-8 mb-10 md:text-7xl text-6xl xl:text-8xl">
                            <span class="bg-gradient-to-r text-transparent bg-clip-text from-[#03BFBE] to-[#6657FF]">To Be together.</span>
                        </p>
                        <a href="#solutions" class="cta-lead-form bg-gradient-to-r focus:outline-none font-bold from-[#03BFBE] inline-flex p-px rounded-full text-lg to-[#6657FF]">
                            <span class="bg-white inline-flex m-px px-5 py-2.5 rounded-full">
                                <span class="px-5 bg-gradient-to-r text-transparent font-semibold bg-clip-text from-[#03BFBE] to-[#6657FF] hover:from-[#6657FF] hover:to-[#03BFBE]">WHAT WE CAN DO FOR YOU</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="lg:mt-0 lg:col-span-5 lg:flex">
                    <img class="w-full" src="../../assets/man-woman.png">
                </div>
            </div>
        </section>
        <hr id="about" class="border-white" />
        <section  class="bg-white my-16">
            <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 max-h-screen">
                <div class="max-w-screen-lg">
                    <h2 class="mb-8 text-5xl md:text-6xl font-sora tracking-tight text-left font-extrabold text-gray-900">Growth is no longer an individual effort — it's a collective force.</h2>
                    <p class="mb-8 font-light font-sora text-left text-gray-600 text-2xl md:text-3xl leading-15">At COREACH, we help leaders and teams evolve by integrating leadership development, emotional intelligence, and organizational culture.</p>
                    <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a href="https://forms.coreach.co/s/cm6z068yk00d0pq01ye2snzr1" target="_blank" class="inline-flex items-center justify-center text-left text-[#03BFBE] mr-3 font-semibold group">
                            KNOW MORE
                            <svg class="w-6 h-6 text-[#03BFBE] ml-1 transition-transform duration-200 ease-in-out group-hover:translate-x-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="solutions" class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
            <div class="flex flex-col titles mb-5">
                <p class="text-gray-500 text-lg font-sora">SOLUTIONS</p>
                <h1 class="font-sora text-5xl font-extrabold text-gray-900">How we help</h1>
            </div>

            <div class="mt-10 grid gap-20 sm:mt-16 lg:grid-cols-2 lg:grid-rows-2">
                <div class="relative lg:row-span-2">
                    <img class="mb-4 w-full object-cover" src="../../assets/orgs-photo-needs-edit.png" alt="orgs-photo.svg">
                    <div class="text-black text-left text-2xl font-extrabold text-wrap py-4 md:px-5 font-sora">
                        Unlock leadership excellence and team cohesion through tailored development programs.
                    </div>
                    <GenericAccordion class="w-full md:px-5" category="organization" />
                </div>
                <div class="relative lg:row-span-2">
                    <img class="mb-4 w-full object-cover" src="../../assets/individ-photo-needs-edit.png" alt="orgs-photo.svg">
                    <div class="text-black text-left text-2xl font-extrabold text-wrap py-4 md:px-5 font-sora">
                        Gain the skills, mindset, and certifications to grow and lead with confidence.
                    </div>
                    <GenericAccordion class="w-full md:px-5" category="individual" />
                </div>
            </div>
            
        </section>

        <section class="flex flex-col items-center justify-items-center py-20 sticky top-20">
            <div class="py-20">
                <h1 class="font-extrabold font-sora leading-none mb-4 md:text-5xl text-5xl tracking-tight xl:text-6xl">Better leaders.</h1>
                <h1 class="font-extrabold font-sora leading-none mb-4 md:text-5xl text-5xl tracking-tight xl:text-6xl">Stronger teams.</h1>
                <p class="font-extrabold font-sora lg:mb-8 mb-6 md:text-5xl text-5xl xl:text-6xl mb-8">
                    <span class="bg-gradient-to-r text-transparent bg-clip-text from-[#03BFBE] to-[#6657FF]">Bigger impact.</span>
                </p>
            </div>
        </section>

        <section class="bg-gray-100 sticky top-20">
            <div class="px-4 py-10 mx-auto text-center lg:py-16 lg:px-6">
                <h1 class="font-extrabold font-sora leading-none mb-6 md:mb-12 md:text-4xl text-3xl tracking-tight xl:text-5xl">We've worked with</h1>
                <div class="grid grid-cols-2 md:gap-8 text-gray-500 gap-4 md:grid-cols-3 lg:grid-cols-6 my-10 md:my-20">
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/1.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/2.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/3.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/4.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/5.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/6.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/7.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/8.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/9.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/10.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/11.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../assets/selected-clients/12.png" class="h-auto md:h-full w-auto object-cover grayscale hover:grayscale-0">
                    </div>
                </div>
            </div>
        </section>

        <section id="cta" class="bg-gradient-radial to-[#6657FF] from-[#03BFBE] py-20 sticky top-20">
            <div class="my-20">
                <h1 class="font-extrabold font-sora leading-none md:text-7xl text-4xl tracking-tight xl:text-7xl mt-10 text-white">Be the Change.</h1>
                <h1 class="font-extrabold font-sora leading-none md:text-7xl text-4xl tracking-tight xl:text-7xl mb-10 text-white">Lead the Future.</h1>
                <h1 class=" font-sora leading-none md:text-3xl text-xl tracking-tight xl:text-4xl mb-10 text-white">Not sure where to start?</h1>
                <a href="https://forms.coreach.co/s/cm6z068yk00d0pq01ye2snzr1" target="_blank" class="focus:outline-none font-bold rounded-full text-lg mb-20">
                    <span class="bg-white inline-flex rounded-full uppercase px-20 py-2">
                        <span class="flex flex-row items-center justify-center gap-2">
                            <span class="bg-gradient-to-r text-transparent font-semibold bg-clip-text from-[#03BFBE] to-[#6657FF] hover:from-[#6657FF] hover:to-[#03BFBE]">
                                Let's Connect
                            </span>
                            <svg class="h-8 w-8 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m10.051 8.102-3.778.322-1.994 1.994a.94.94 0 0 0 .533 1.6l2.698.316m8.39 1.617-.322 3.78-1.994 1.994a.94.94 0 0 1-1.595-.533l-.4-2.652m8.166-11.174a1.366 1.366 0 0 0-1.12-1.12c-1.616-.279-4.906-.623-6.38.853-1.671 1.672-5.211 8.015-6.31 10.023a.932.932 0 0 0 .162 1.111l.828.835.833.832a.932.932 0 0 0 1.111.163c2.008-1.102 8.35-4.642 10.021-6.312 1.475-1.478 1.133-4.77.855-6.385Zm-2.961 3.722a1.88 1.88 0 1 1-3.76 0 1.88 1.88 0 0 1 3.76 0Z"/>
                            </svg>
                        </span>
                    </span>
                </a>
            </div>
        </section>

        <div class="sticky top-20">
            <section class="bg-white md:flex gap-0">
                <div class="w-screen max-w-screen-xl mx-auto h-full py-16 flex-col align-middle mt-20">
                    <p class="font-extrabold font-sora md:text-6xl text-5xl xl:text-7xl mb-0">
                        <span class="bg-gradient-to-r text-transparent bg-clip-text from-[#03BFBE] to-[#6657FF]">Reach Further,</span>
                    </p>
                    <h1 class="font-extrabold font-sora leading-none mb-4 md:text-5xl text-4xl tracking-tight xl:text-6xl">Grow Faster!</h1>
                    <p class="font-sora text-center text-black text-xl md:text-2xl leading-15 px-10 my-12 w-full">
                        Your personalized coaching experience is here - simplify scheduling, streamline collaboration and take control of your journey with REACH.    
                    </p>
                    <div class="flex gap-12 justify-center">
                        <!-- <a href="#" class="bg-gradient-to-r focus:outline-none font-bold from-[#03BFBE] inline-flex p-px rounded-full text-lg to-[#6657FF]">
                            <span class="bg-white inline-flex m-px px-5 py-2.5 rounded-full uppercase font-bold">
                                know more
                                <svg class="w-6 h-6 text-black ml-1 transition-transform duration-200 ease-in-out group-hover:translate-x-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                </svg>
                            </span>
                        </a> -->
                        <a href="https://forms.coreach.co/s/cm6z068yk00d0pq01ye2snzr1" target="_blank" class="font-bold px-6 rounded-full text-lg bg-gradient-to-r from-[#03BFBE] to-[#6657FF]">
                            <span class="text-white inline-flex m-px px-5 py-2.5 rounded-full uppercase">
                                Request a Demo
                            </span>
                        </a>
                    </div>
                </div>
                <!-- <div class="hidden md:block w-screen md:w-1/2">
                    <img src="../../assets/reach-phone.png" class="h-full object-cover" alt="REACH Journeys by COREACH">
                </div> -->
            </section>

            <section class="bg-gray-100 sticky top-20">
                <div class=" px-4 py-10 mx-auto text-center lg:py-16 lg:px-6">
                    <div class="my-20">
                        <h1 class="font-extrabold font-sora leading-none mb-12 md:text-4xl text-3xl tracking-tight xl:text-5xl">Our Partners</h1>
                        <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-5 my-10">
                            <div class="flex flex-col items-center justify-center">
                                <img src="../../assets/erickson-icon.png" class="h-auto w-[180px] grayscale hover:grayscale-0">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <img src="../../assets/mhs-icon.svg" class="h-auto w-[180px] grayscale hover:grayscale-0">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <img src="../../assets/holocracy-icon.svg" class="h-auto w-[190px] grayscale hover:grayscale-0">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <img src="../../assets/human-element-icon.png" class="h-auto w-[150px] grayscale hover:grayscale-0">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <img src="../../assets/icf-icon.png" class="h-auto w-[100px] grayscale hover:grayscale-0">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>

    <CoreachFooter />
</template>

<script>
import GenericAccordion from '../dynamic/GenericAccordion.vue';
import CoreachNavbar from '../secondary/Navbar.vue';
import CoreachFooter from '../secondary/Footer.vue';

export default {
    name: 'LandingPage',
    components: {
        CoreachNavbar,
        GenericAccordion,
        CoreachFooter,
    },
}
</script>

<style scoped>
</style>
  