<template>
  <div
    :id="`accordion-flush-${category}`"
    data-accordion="collapse"
    data-active-classes="bg-white text-indigo-500 font-semibold"
    data-inactive-classes="text-gray-500"
  >
    <div
      v-for="solution of solutions.filter((s) => s.category == category)"
      :key="solution.slug"
      class="group"
    >
      <h2 :id="`accordion-flush-heading-${category}-${solution.slug}`">
        <button
          type="button"
          class="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 gap-3"
          :data-accordion-target="`#accordion-flush-body-${category}-${solution.slug}`"
          aria-expanded="true"
          :aria-controls="`accordion-flush-body-${category}-${solution.slug}`"
        >
          <span class="font-sora">{{ solution.title }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-3 h-3 shrink-0 group-hover:stroke-indigo-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </button>
      </h2>
      <div
        :id="`accordion-flush-body-${category}-${solution.slug}`"
        class="hidden"
        :aria-labelledby="`accordion-flush-heading-${category}-${solution.slug}`"
      >
        <div class="py-5 border-b border-gray-200">
          <p
            class="mb-2 text-gray-500 text-left text-sm font-sora"
          >
            {{ solution.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initFlowbite } from "flowbite";
export default {
  name: "GenericAccordion",
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openSection: null,
      solutions: [
        {
          title: "Leadership & Talent Development Programs",
          slug: "leadership-talent-development",
          category: "organization",
          description:
            "Empowering leaders with tailored development plans, psychometric assessments, and executive coaching to drive organizational success.",
        },
        {
          title: "Coaching & Mentoring Solutions",
          slug: "coaching-mentoring-solutions",
          category: "organization",
          description:
            "Customized coaching and mentoring initiatives designed to enhance leadership capabilities, decision-making, and team performance.",
        },
        {
          title: "Organizational Growth & Transformation Strategies",
          slug: "organizational-growth-transformation",
          category: "organization",
          description:
            "Comprehensive strategies that support cultural transformation, leadership alignment, and organizational development for long-term impact.",
        },
        {
          title: "Employee Well-being & Engagement Initiatives",
          slug: "employee-wellbeing-engagement",
          category: "organization",
          description:
            "Programs that foster a humanized workplace, enhancing employee resilience, emotional intelligence, and overall engagement.",
        },
        {
          title: "Customized Training & Development Programs",
          slug: "customized-training-development",
          category: "organization",
          description:
            "Tailored in-house learning experiences, workshops, and training solutions to strengthen leadership, teamwork, and innovation.",
        },
        {
          title: "AI-Powered Insights for Workforce Development",
          slug: "ai-workforce-development",
          category: "organization",
          description:
            "Utilizing AI-driven analytics and behavioral insights to personalize learning, optimize leadership growth, and maximize organizational performance.",
        },
        {
          title: "Personalized Coaching Journeys",
          slug: "personalized-coaching-journeys",
          category: "individual",
          description:
            "Structured coaching pathways that guide individuals through self-discovery, leadership mastery, and personal development.",
        },
        {
          title: "Career & Leadership Development",
          slug: "career-leadership-development",
          category: "individual",
          description:
            "Equipping professionals with the skills, mindset, and strategies needed to accelerate career progression and leadership excellence.",
        },
        {
          title: "Self-Growth & Transformation Programs",
          slug: "self-growth-transformation",
          category: "individual",
          description:
            "Curated programs focused on emotional resilience, mindset shifts, and personal fulfillment for a transformative growth journey.",
        },
        {
          title: "Well-being & Work-Life Balance Coaching",
          slug: "wellbeing-work-life-balance",
          category: "individual",
          description:
            "Holistic coaching designed to strengthen emotional well-being, enhance resilience, and create a balanced, purpose-driven life.",
        },
        {
          title: "Digital Tools & AI-Driven Personal Growth Insights",
          slug: "digital-tools-ai-growth",
          category: "individual",
          description:
            "Leveraging AI and digital solutions to provide real-time coaching insights, behavioral feedback, and personalized growth recommendations.",
        },
      ],
    };
  },
  mounted() {
    initFlowbite();
  },
};
</script>
