<template>
    <div v-if="visible" class="fixed z-50 top-0 left-0 w-screen h-screen bg-white">
      <canvas ref="preloaderCanvas"></canvas>
      <div
        ref="rotatingValues"
        class="rotating__values relative h-screen flex items-center justify-between w-screen"
      >
        <span v-for="(word, index) in words" :key="index" class="font-sora font-medium">{{ word }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PreloaderFull',
    props: {
      timeout: {
        type: Number,
        default: null
      },
      appLoaded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visible: true,
        words: ["Connecting", "Evolving", "Humanizing", "Heart Beating", "Creating Growth", "Co-creating", "Aspiring", "Listening"],
        currentIndex: 0,
        time: 0
      };
    },
    watch: {
      appLoaded(newVal) {
        if (newVal) this.hidePreloader();
      }
    },
    mounted() {
      this.setupCanvas();
      this.rotateText();
      if (this.timeout) {
        setTimeout(this.hidePreloader, this.timeout);
      }
    //   window.addEventListener("resize", this.resizeCanvas);
    },
    beforeUnmount() {
    //   window.removeEventListener("resize", this.resizeCanvas);
    },
    methods: {
      hidePreloader() {
        const canvas = this.$refs.preloaderCanvas;
        canvas.classList.add("animate-out");
        setTimeout(() => {
          this.visible = false;
        }, 501);
      },
      setupCanvas() {
        const canvas = this.$refs.preloaderCanvas;
        const ctx = canvas.getContext("2d");
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        const createGradient = () => {
          const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
          gradient.addColorStop(0, "#00e391");
          gradient.addColorStop(0.25, "#14c6a7");
          gradient.addColorStop(0.5, "#3796cc");
          gradient.addColorStop(0.75, "#5073e8");
          gradient.addColorStop(1, "#6657ff");
          return gradient;
        };
  
        const gradient1 = createGradient();
        const gradient2 = createGradient();
  
        const drawBlob = (x, y, width, height, blur, gradient, distortion) => {
          ctx.filter = `blur(${blur}px)`;
          ctx.beginPath();
          for (let i = 0; i < Math.PI * 2; i += 0.02) {
            const angle = i;
            const radiusX = width + Math.sin(i + this.time * 0.5) * distortion;
            const radiusY = height + Math.cos(i + this.time * 0.5) * distortion;
            ctx.lineTo(x + Math.cos(angle) * radiusX, y + Math.sin(angle) * radiusY);
          }
          ctx.closePath();
  
          const shapeGradient = ctx.createLinearGradient(x - width, y - height, x + width, y + height);
          shapeGradient.addColorStop(0, "#00e391");
          shapeGradient.addColorStop(0.25, "#14c6a7");
          shapeGradient.addColorStop(0.5, "#3796cc");
          shapeGradient.addColorStop(0.75, "#5073e8");
          shapeGradient.addColorStop(1, "#6657ff");
  
          ctx.fillStyle = shapeGradient;
          ctx.fill();
        };
  
        const animate = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const x1 = canvas.width / 2 + Math.cos(this.time * 1.5) * 50;
          const y1 = canvas.height / 2 + Math.sin(this.time * 1.5) * 50;
          const x2 = canvas.width / 2 + Math.cos(this.time) * 50;
          const y2 = canvas.height / 2 + Math.sin(this.time) * 50;
  
          drawBlob(x1, y1, 120, 100, 15, gradient1, 10);
          drawBlob(x2, y2, 130, 110, 0, gradient2, 10);
  
          this.time += 0.015;
          requestAnimationFrame(animate);
        };
  
        animate();
      },
      resizeCanvas() {
        const canvas = this.$refs.preloaderCanvas;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      },
      rotateText() {
        const values = this.$refs.rotatingValues ? Array.from(this.$refs.rotatingValues.querySelectorAll("span")) : [];
        values.sort(() => Math.random() - 0.5);
        values.forEach((span, index) => {
          span.classList.remove("active", "exit");
          if (index === this.currentIndex) {
            span.classList.add("active");
          } else if (index === (this.currentIndex - 1 + values.length) % values.length) {
            span.classList.add("exit");
          }
        });
  
        this.currentIndex = (this.currentIndex + 1) % values.length;
        setTimeout(this.rotateText, 1200);
      }
    }
  };
  </script>
  
  <style scoped>
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  .rotating__values span {
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    font-size: 2rem;
    width: 100%;
    color: white;
    text-align: center;
    display: block;
  }
  .rotating__values span.active {
    opacity: 1;
    transform: translateY(0);
  }
  .rotating__values span.exit {
    opacity: 0;
    transform: translateY(-20px);
  }
  .animate-out {
    opacity: 0;
    transform: translateY(-600px) scale(60, 60);
  }
  </style>
  