<template>
   <nav class="bg-white/80 fixed w-full z-20 top-0 start-0 backdrop-blur-md backdrop-brightness-150">
      <div class="flex flex-wrap items-center justify-between mx-auto px-4 py-4 md:px-10 lg:px-16 md:py-6">
         <LogoGradiant />
         <div class="flex justify-end w-full md:w-auto md:mt-0 md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse font-sora">
               <a href="https://forms.coreach.co/s/cm6z068yk00d0pq01ye2snzr1" target="_blank" class="cta-lead-form hidden md:inline-flex items-center gap-2 text-white uppercase text-base font-medium bg-gradient-to-br from-teal-500 via-[#03BFBE] to-[#6657FF] focus:ring-4 focus:outline-none focus:ring-teal-300 rounded-full px-4 py-2 text-center">
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m10.051 8.102-3.778.322-1.994 1.994a.94.94 0 0 0 .533 1.6l2.698.316m8.39 1.617-.322 3.78-1.994 1.994a.94.94 0 0 1-1.595-.533l-.4-2.652m8.166-11.174a1.366 1.366 0 0 0-1.12-1.12c-1.616-.279-4.906-.623-6.38.853-1.671 1.672-5.211 8.015-6.31 10.023a.932.932 0 0 0 .162 1.111l.828.835.833.832a.932.932 0 0 0 1.111.163c2.008-1.102 8.35-4.642 10.021-6.312 1.475-1.478 1.133-4.77.855-6.385Zm-2.961 3.722a1.88 1.88 0 1 1-3.76 0 1.88 1.88 0 0 1 3.76 0Z"/>
                  </svg>
                  Get started
               </a>
               <button data-collapse-toggle="navbar-sticky" type="button" class="absolute top-3 right-4 inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky" aria-expanded="false">
               <span class="sr-only">Open main menu</span>
               <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
               </svg>
            </button>
         </div>
         <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 font-sora" id="navbar-sticky">
            <ul class="flex uppercase flex-col p-4 md:p-0 mt-4 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
               <li>
               <a href="#solutions" class="text-lg block py-2 px-3 text-gray-500 rounded-sm hover:bg-gradient-to-r hover:text-transparent hover:bg-clip-text hover:from-[#03BFBE] hover:to-[#6657FF]">Solutions</a>
               </li>
               <li>
               <a href="#about" class="text-lg block py-2 px-3 text-gray-500 rounded-sm hover:bg-gradient-to-r hover:text-transparent hover:bg-clip-text hover:from-[#03BFBE] hover:to-[#6657FF]">About Us</a>
               </li>
               <li>
               <a href="#cta" class="cta-lead-form text-lg block py-2 px-3 text-gray-500 rounded-sm hover:bg-gradient-to-r hover:text-transparent hover:bg-clip-text hover:from-[#03BFBE] hover:to-[#6657FF]">Work with us</a>
               </li>
            </ul>
         </div>

      </div>
   </nav>
</template>

<script>
import LogoGradiant from '../dynamic/LogoGradiant.vue';

   export default {
      name: 'CoreachNavbar',
      components: {
         LogoGradiant,
      }
   }
</script>

<style scoped>
</style>  