<template>
  <Preloader timeout="4000" />
  <LandingPage class="font-sora" />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import LandingPage from './components/main/LandingPage.vue';
import Preloader from './components/secondary/Preloader.vue';
export default {
  name: 'App',
  components: {
    LandingPage,
    Preloader
  }
}
</script>

<style>
#app {
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
