<template>
    <footer class="p-4 bg-black sm:p-6">
        <div class="mx-auto max-w-screen-xl">
            <div class="md:flex md:justify-between md:gap-0">
                <div class="grid grid-cols-1 gap-8 md:gap-12 md:grid-cols-3 lg:gap-16 lg:grid-cols-4">
                    <div class="text-left">
                        <h2 class="mb-2 md:mb-6 text-sm font-semibold text-[#797979] uppercase">Solutions</h2>
                        <ul class="text-white">
                            <li class="mb-4">
                                <a href="#solutions" class="hover:underline">For organizations</a>
                            </li>
                            <li>
                                <a href="#solutions" class="hover:underline">For individuals</a>
                            </li>
                        </ul>
                    </div>
                    <div class="text-left">
                        <h2 class="mb-2 md:mb-6 text-sm font-semibold text-[#797979] uppercase">Company</h2>
                        <ul class="text-white">
                            <li class="mb-4">
                                <a href="#about" class="hover:underline">About</a>
                            </li>
                            <li>
                                <a href="#partners" class="hover:underline">Partners</a>
                            </li>
                        </ul>
                    </div>
                    <div class="text-left">
                        <h2 class="mb-2 md:mb-6 text-sm font-semibold text-[#797979] uppercase">Resources</h2>
                        <ul class="text-white">
                            <li class="mb-4">
                                <a href="https://reachjourneys.com" class="hover:underline">REACH Journeys</a>
                            </li>
                        </ul>
                    </div>
                    <div class="text-left">
                        <h2 class="mb-2 md:mb-6 text-sm font-semibold text-[#797979] uppercase">Contact us</h2>
                        <ul class="text-white">
                            <li class="mb-4 flex align-middle items-center">
                                <a href="tel:+966543031113" target="_blank">
                                    <span class="flex align-middle justify-start gap-1 text-xs text-white/40">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-white">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
                                    </svg>
                                    Call
                                    </span>
                                    <span>+966 543 031 113</span>
                                </a>
                            </li>
                            <li class="mb-4 flex align-middle items-center">
                                <a href="mailto:reach@coreach.co" target="_blank">
                                    <span class="flex align-middle justify-start gap-1 text-xs text-white/40">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-white">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                        </svg>
                                    Email</span>
                                    <span>reach@coreach.co</span>
                                </a>
                            </li>
                            <li class="mb-4 flex align-middle items-center">
                                <a href="https://wa.me/966543031113?text=👋 Hello" target="_blank">
                                    <span class="flex align-middle justify-start gap-1 text-xs text-white/40">
                                        <svg
                                        id="whatsapp"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        class="w-3 h-3"
                                        >
                                            <path
                                            fill="#7ad06d"
                                            d="m11.15.01C4.91.31-.02,5.51,0,11.76c0,1.9.47,3.7,1.28,5.29L.03,23.08c-.07.33.23.61.55.54l5.92-1.4c1.52.76,3.23,1.19,5.04,1.22,6.38.1,11.7-4.97,11.9-11.34C23.65,5.26,18.01-.31,11.15.01Zm7.06,18.2c-1.73,1.73-4.04,2.69-6.49,2.69-1.44,0-2.81-.32-4.08-.96l-.82-.41-3.63.86.76-3.71-.41-.8c-.66-1.3-1-2.7-1-4.17,0-2.45.95-4.76,2.69-6.49,1.72-1.72,4.06-2.69,6.49-2.69,2.45,0,4.76.95,6.49,2.69,1.73,1.73,2.69,4.04,2.69,6.49,0,2.43-.97,4.77-2.69,6.49Z"
                                            /><path
                                            fill="#7ad06d"
                                            d="m17.41,14.26l-2.27-.65c-.3-.09-.62,0-.84.22l-.56.57c-.23.24-.59.32-.9.19-1.07-.43-3.33-2.44-3.91-3.45-.17-.29-.14-.65.07-.92l.48-.63c.19-.25.23-.58.1-.86l-.96-2.16c-.23-.52-.89-.67-1.32-.3-.63.54-1.39,1.35-1.48,2.25-.16,1.59.52,3.6,3.1,6,2.98,2.78,5.37,3.15,6.92,2.77.88-.21,1.59-1.07,2.03-1.77.3-.48.06-1.11-.48-1.27Z"
                                            />
                                        </svg>
                                        Whatsapp
                                    </span>
                                    <span>+966 543 031 113</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex gap-4 items-start align-middle justify-between mt-8 md:mt-0">
                    <div class="text-xs sm:text-sm text-left text-white/40">
                        <span class="text-white/60">COREACH</span> | EMAR ELFAKER Co.<br/>
                        National Unified# <b>7003322679</b><br/> CR# <b>1010336455</b><br/> VAT# <b>311142255900003</b><br/><br/>
                        Office #16, Pearl Building, Othman bin Affan St.<br/>King Salman District - Riyadh 12445 Saudi Arabia
                    </div>
                    <img src="../../assets/coreach-symbol.svg" class="w-20 h-auto grayscale hover:grayscale-0" />
                </div>
            </div>
            <hr class="my-6 border-gray-800 sm:mx-auto lg:my-8" />
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="flex items-center align-middle justify-between gap-3 mb-4 md:mb-0">
                    <a href="" class="hover:underline text-white">Terms & Conditions</a>
                    |
                    <a href="" class="hover:underline text-white">Privacy Policy</a>
                </div>
                <span class="text-sm text-gray-500 sm:text-center">© COREACH 2025. All Rights Reserved.
                </span>
                <div class="flex space-x-6 items-center justify-between sm:justify-center mt-4 md:mt-0">
                    <p class="mr-3 uppercase text-[#797979] font-bold">find us on socials</p>
                    <a href="https://www.linkedin.com/company/coreach/" target="_blank" class="text-white hover:text-gray-900">
                        <svg class="w-6 h-6 text-white hover:text-white/60" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clip-rule="evenodd"/>
                            <path d="M7.2 8.809H4V19.5h3.2V8.809Z"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/coreachco/?hl=en" target="_blank" class="text-white hover:text-gray-900">
                        <svg class="w-6 h-6 text-white hover:text-white/60" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd"/>
                        </svg>
                    </a>
                    <a href="https://x.com/coREACHco" target="_blank" class="text-white hover:text-gray-900">
                        <svg class="w-4 h-4 text-white hover:text-white/60" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="m23.24,21.74c-.12,0-.19,0-.27,0-2.16,0-4.33,0-6.49,0-.14,0-.21-.05-.29-.16-2.04-2.73-4.09-5.46-6.12-8.2-.14-.18-.19-.12-.31,0-1.61,1.75-3.22,3.49-4.84,5.23-.92,1-1.85,2-2.77,3-.08.09-.16.13-.28.13-.55,0-1.09,0-1.64,0-.07,0-.13,0-.24-.01.39-.42.75-.81,1.11-1.2,2.38-2.57,4.77-5.15,7.15-7.72.22-.23.43-.47.65-.69.1-.1.08-.16,0-.26-1.6-2.14-3.2-4.28-4.8-6.42C2.81,3.7,1.51,1.96.21.22c-.04-.06-.09-.12-.13-.18C.14-.02.19,0,.25,0,2.43,0,4.61,0,6.8,0c.14,0,.22.05.3.16,1.78,2.38,3.55,4.76,5.33,7.13.1.13.21.26.3.4.09.14.16.11.26,0,.43-.47.87-.94,1.31-1.41,1.67-1.8,3.34-3.61,5.01-5.41.23-.25.47-.51.7-.76.06-.06.11-.11.21-.11.61,0,1.22,0,1.82,0,.02,0,.05,0,.11.02-.19.21-.36.39-.53.58-2.27,2.45-4.53,4.89-6.8,7.34-.32.34-.63.69-.95,1.04-.08.08-.09.13-.02.22,2.07,2.76,4.13,5.52,6.19,8.28,1.05,1.41,2.11,2.82,3.2,4.27Zm-3.05-1.54c-.06-.08-.11-.16-.16-.24-1.96-2.63-3.93-5.25-5.89-7.88-2.6-3.48-5.2-6.95-7.8-10.43-.08-.11-.17-.16-.31-.16-.85,0-1.7,0-2.55,0-.11,0-.29-.05-.33.03-.06.11.1.21.17.3,2.35,3.15,4.7,6.29,7.06,9.44,2.2,2.94,4.4,5.88,6.59,8.83.07.1.15.14.27.14.89,0,1.78,0,2.67,0,.09,0,.18.02.28-.03Z" clip-rule="evenodd"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>
 
<script>
export default {
    name: 'CoreachFooter',
    components: {
        
    }
}
</script>